import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button,
  AppBar,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Grid,
  Divider,
  Chip,
  CircularProgress
} from '@material-ui/core';
import {
  Pagination,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineDot,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import classNames from 'classnames';

import ProfileMenu from '../ProfileMenu.js';
import { getAvailableWordsLog } from '../../../modules/wordslog.js';
import { formatDate, formatLargeNumber } from '../../../helper.js';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTimelineOppositeContent-root': {
      flex: '1 !important'
    }
  },
  stickyAppBar: {
    top: 40,
  },
  table: {
    minWidth: 650,
  },
  label: {
      width: 200,
      background: '#fff',
      borderRight: 'solid 1px #ccc',
      position: 'sticky',
      left: 0,
      zIndex: 1
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12
  },
  statusChip: {
    marginLeft: 16,
    fontSize: '1rem'
  },
  transactionAdd: {
    color: 'green',
    fontWeight: 'bold',
    '&::before': {
      content: '"+"',
    }
  },
  transactionSpend: {
    color: 'red',
    fontWeight: 'bold',
  },
  transactionInitialWordsLeft: {
    color: 'grey',
    '&::before': {
      content: '"+"',
    }
  },
  transactionWordsLeft: {
    borderTop: 'solid 1px grey',
    fontWeight: '500',
    '&::before': {
      content: '"+"',
    }
  },
  errorMessageTxt: {
    textAlign: 'center',
    padding: '5rem'
  }
}));



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    verticalAlign: 'top'
  },
  body: {
    fontSize: 14,
    verticalAlign: 'top'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export function WordsLogTimeline(state, props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const availableWordsLog = useSelector((state) => state.wordslog.available_words_log);
  const totalPages = useSelector((state) => state.wordslog.totalPages);
  const currentPage = useSelector((state) => state.wordslog.currentPage);
  const total = useSelector((state) => state.wordslog.total);
  const errorMessage = useSelector((state) => state.wordslog.errorMessage);
  const availableWords = useSelector((state) => state.shop.shop.auto_translations.available_words);


  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchItems = useCallback(async () => {
    if (errorMessage || isLoading || !hasMore) return;

    setIsLoading(true);
    dispatch(getAvailableWordsLog((res) => {
      console.log(res);
      setIsLoading(false);

      if(res.currentPage === res.totalPages) {
        window.removeEventListener('scroll', handleScroll);
        setHasMore(false);
      }
    }));
  }, [isLoading, hasMore]);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading || !hasMore) return;
    console.log('handleScroll');
    fetchItems();
  }, [fetchItems, isLoading]);

  useEffect(() => {
    console.log('useEffect');
    fetchItems();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchItems, handleScroll]);


  return (<div className={classes.root}>
    <AppBar position="sticky" color="default" dense className={classes.stickyAppBar}>
      <Toolbar style={{justifyContent: 'end', fontSize: '1rem'}}>
        <Typography variant="h6" textAlign="right">Available auto-translation words: <strong>{formatLargeNumber(availableWords || 0)}</strong></Typography>
      </Toolbar>
    </AppBar>

    {(!availableWordsLog && !isLoading) || (availableWordsLog.length === 0 && !isLoading && !hasMore) || errorMessage ?
      <Typography className={classes.errorMessageTxt}>
        {errorMessage ? errorMessage : 'Sorry, no logs available'}
      </Typography>
    :
      <React.Fragment>
        <TableContainer component={Paper}>


      
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Service</TableCell>
                <TableCell align="left">Target</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="right">Words Saldo</TableCell>
              </TableRow>
            </TableHead>


            <TableBody>
              {availableWordsLog && availableWordsLog.map((row) => (
                <StyledTableRow key={`word-log-entry-${row.id}`}>
                  <StyledTableCell align="left">
                    {formatDate(row.actionDate)}
                    <br/>
                    <Typography variant="caption">#{row.id}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.actionType}</StyledTableCell>
                  <StyledTableCell align="left">{row.translationService}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.targetLanguage}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.actionType === 'single' ?
                      <a href={`${window.location.href.replace(window.location.pathname, '')}${row.targetSections}`} target="_blank">{row.targetSections}</a>
                    :
                      <>{row.targetSections}</>
                    }
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <span className={classes.transactionInitialWordsLeft}>
                      {formatLargeNumber(row.newWordTotal + (row.wordTransaction * -1))}
                    </span>
                    <br/>
                    <span className={classNames(
                      row.wordTransaction > 0 && classes.transactionAdd,
                      row.wordTransaction < 0 && classes.transactionSpend
                    )}>
                      {formatLargeNumber(row.wordTransaction)}
                    </span>
                    <br/>
                    <span className={classes.transactionWordsLeft}>{formatLargeNumber(row.newWordTotal)}</span>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {isLoading && hasMore && 
            <div style={{ textAlign: 'center', padding: availableWordsLog.length === 0 ? '5rem' : '1rem'}}>
              <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
            </div>
          }
        </TableContainer>
      </React.Fragment>
       
    }
  </div>);
}


const WordsLogPage = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3} alignItems="flex-start" className={classNames(classes.root, "page-content")}>
        <Grid item xs={12}>
          <ProfileMenu />
        </Grid>
        <Grid item xs={12} className="page-content">
          <Typography variant="h4" component="h2">Available Words Log</Typography>
          <Divider className="divider-white" />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <WordsLogTimeline 
              classes={classes}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default (WordsLogPage);
