import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLangifyNotificationAsUnviewed, setLangifyNotificationAsViewed, getLangifyNotifications, resetErrors } from '../../../modules/langifyNotifications';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Paper, Grid } from '@material-ui/core';
import ItemsList from '../../../components/ItemsList';
import ItemsListSkeleton from '../../../components/ItemsListSkeleton';
import LoadingIndicator from '../../../components/LoadingIndicator';
import EmptyView from '../../../components/EmptyView';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OptionsMenu from '../../../components/OptionsMenu';
import NewFlexItemList from '../../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
import Link from '@material-ui/core/Link';
import { Trans, useTranslation } from 'react-i18next';
import { ThreeSixtySharp } from '@material-ui/icons';

import ProfileMenu from '../ProfileMenu.js';
import Ticketsystem from './Ticketsystem.js';

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    marginBottom: 24,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
  }
}));

const Profile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const notifications = useSelector(state => state.langifyNotifications.notifications);
  const editLanguage = useSelector(state => state.shop.shop.edit_language);
  const shopUrl = useSelector(state => state.shop.shop.url);

  useEffect(() => {
    // Your effect logic here if needed
    console.log(id);
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3} alignItems="flex-start" className="page-content">
        <Grid item xs={12}>
          <ProfileMenu />
        </Grid>
        <Grid item xs={12} className="page-content">
          <Typography variant="h4" component="h2">{t('Support Tickets')}</Typography>
          <Divider className="divider-white" />
        </Grid>
        <Grid item xs={12}>
          <Ticketsystem />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Profile;