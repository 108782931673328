import React, { Component } from "react";
import classNames from 'classnames';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';

window.overPopup = false;

export const CustomSwitcher = (props, state, toggleCallback) => {
  let arrowScaleFactor = state.arrow_size * 0.01;
  let realsize = state.fontsize * arrowScaleFactor;
  let isDropup = state.is_dropup ? state.is_dropup : state.corner.indexOf('bottom') > -1;
  let languages = state.languages.sort(function(a, b){  
    return state.itemsOrder.indexOf(a) - state.itemsOrder.indexOf(b);
  });
  if(languages.length === 0) return null;
  let activeLang = (languages.findIndex(x => x.primary == true) != -1) ? languages.find(x => x.primary == true) : languages.find(x => x.published == true);
  if(!activeLang) activeLang = languages[0];
  return (
    <div 
      data-dropup={isDropup} 
      onClick={toggleCallback}
      className={classNames(
        'ly-languages-switcher', 
        'ly-custom', 
        'ly-custom-dropdown-switcher', 
        state.is_dark == false && 'ly-bright-theme', 
        state.dropdown_open && 'ly-is-open',
        state.is_transparent && 'ly-is-transparent',
        isDropup && 'ly-is-dropup',
      )}
      style={{
        //fontSize: state.fontsize,
        margin: `${state.v_item_space}px ${state.h_item_space}px`,
      }}
    >
      <span className="ly-custom-dropdown-current" 
        style={{
          color: state.text_color,
          backgroundColor: !state.is_transparent ? state.bg_color : 'inherit',
          padding: `${state.v_item_padding}px ${state.h_item_padding}px`,
          borderStyle: 'solid', 
          borderColor: state.border_color, 
          borderRadius: state.border_radius, 
          borderWidth: state.border_width
        }}
      >
        {(state.square_icons || state.round_icons || state.rectangle_icons) && <i className={classNames('ly-flag-icon', state.square_icons ? 'ly-flag-squared' : null, state.round_icons ? 'ly-flag-rounded' : null)} style={{backgroundImage: `url('/flag-icons/${activeLang.iso_code}${state.round_icons || state.square_icons ? '_1x1' : ''}.svg')`}} />}
        <span className="ly-custom-dropdown-current-inner ly-custom-dropdown-current-inner-text" style={{color: state.text_color}}>
          {state.show_name && props.available_language_codes.find(x => activeLang.iso_code) && props.available_language_codes.find(x => activeLang.iso_code).name}
          {state.show_custom_name && !activeLang.custom_name && activeLang.name}
          {state.show_custom_name && activeLang.custom_name && activeLang.custom_name}
          {state.show_iso_code && activeLang.iso_code.toUpperCase()}
        </span>
        <span 
          className={classNames(
            'ly-arrow',
            isDropup ? 'ly-arrow-up' : 'ly-arrow-down', 
            state.arrow_filled ? 'caret' : 'stroke',
          )}
          style={{width: realsize, height: realsize, verticalAlign: 'middle'}}
        >     
          <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox={`0 0 ${realsize} ${realsize}`} 
            height={`${realsize}px`} 
            width={`${realsize}px`}
            style={{position: 'absolute'}}
          >
            <path 
              d={`
                M${ state.arrow_width } ${ state.arrow_width + realsize/4 }
                L${ realsize/2 } ${ realsize/2 + realsize/4 }
                L${ realsize - state.arrow_width } ${ state.arrow_width + realsize/4 }
              `}
              fill={ state.arrow_filled ? state.text_color : "transparent" }
              stroke={ state.arrow_filled ? "transparent" : state.text_color }
              strokeWidth={ state.arrow_filled ? 0 : state.arrow_width }
            />
          </svg>
        </span>  

      </span>
      <ul className={classNames(
          'ly-custom-dropdown-list', 
          state.dropdown_open && 'ly-is-open',
          state.is_dark == false && 'ly-bright-theme'
        )} 
        style={{
          background: state.bg_color,
          //padding: `${state.v_item_padding}px ${state.h_item_padding}px`,
          borderStyle: 'solid', 
          borderColor: state.border_color, 
          borderRadius: state.border_radius, 
          borderWidth: state.border_width
        }}>
        {languages.map((language, index) => {
          return (language.published && !language.primary) ? 
          <li key={`${language.iso_code}-${index}`} style={{padding: `${state.v_item_padding}px ${state.h_item_padding}px`}}>
            <a className={classNames('ly-custom-dropdown-list-element', 'ly-languages-link', state.is_dark == false && 'ly-bright-theme')} style={{color: `${state.text_color} !important`}} href="#">
              {/*<img className="ly-custom-dropdown-list-element-left" src={''} />*/}
              {(state.square_icons || state.round_icons || state.rectangle_icons) && <i className={classNames('ly-flag-icon', state.square_icons ? 'ly-flag-squared' : null, state.round_icons ? 'ly-flag-rounded' : null)} style={{backgroundImage: `url('/flag-icons/${language.iso_code}${state.round_icons || state.square_icons ? '_1x1' : ''}.svg')`}} />}
              {state.show_name && <span className="ly-custom-dropdown-list-element-right">{props.available_language_codes.find(x => x.code == language.iso_code) && props.available_language_codes.find(x => x.code == language.iso_code).name}</span>}
              {state.show_custom_name && <span className="ly-custom-dropdown-list-element-right">{language.custom_name ? language.custom_name : language.name}</span>}
              {state.show_iso_code && <span className="ly-custom-dropdown-list-element-right">{language.iso_code.toUpperCase()}</span>}
            </a>
          </li> : null;
        })}
      </ul>
    </div>
  );
}


export const PopupSwitcher = (props, state, toggleCallback) => {
  let languages = state.languages.sort(function(a, b){  
    return state.itemsOrder.indexOf(a) - state.itemsOrder.indexOf(b);
  });
  if(languages.length === 0) return null;
  let activeLang = (languages.findIndex(x => x.primary == true) != -1) ? languages.find(x => x.primary == true) : languages.find(x => x.published == true);
  if(!activeLang) activeLang = languages[0];
  return (
    <div className={classNames('ly-languages-switcher', 'ly-popup-switcher', state.is_transparent && 'ly-is-transparent', state.position, state.position !== 'custom' && state.corner)}>
      <div className="ly-popup-current" onClick={toggleCallback}
        style={{
          backgroundColor: !state.is_transparent ? state.bg_color : 'inherit',
          padding: `${state.v_item_padding}px ${state.h_item_padding}px`,
          borderStyle: 'solid', 
          borderColor: state.border_color, 
          borderRadius: state.border_radius, 
          borderWidth: state.border_width,
        }}
      >
        {state.popup_show_world_icon && <span><PublicOutlinedIcon /></span>}
        {state.show_flags && <i className={classNames('ly-flag-icon', state.square_icons ? 'ly-flag-squared' : null, state.round_icons ? 'ly-flag-rounded' : null)} style={{backgroundImage: `url('/flag-icons/${activeLang.iso_code}${state.round_icons || state.square_icons ? '_1x1' : ''}.svg')`}} />}
        <span className="ly-popup-current-inner ly-popup-current-inner-text" style={{color: state.text_color}}>
          {state.show_name && props.available_language_codes.find(x => activeLang.iso_code) && props.available_language_codes.find(x => activeLang.iso_code).name}
          {state.show_custom_name && !activeLang.custom_name && activeLang.name}
          {state.show_custom_name && activeLang.custom_name && activeLang.custom_name}
          {state.show_iso_code && activeLang.iso_code.toUpperCase()}
        </span>
      </div>
    </div>
  );
}

export const PopupSwitcherModal = (props, state, toggleCallback, toggleCountryCallback, toggleCurrencyCallback, togglePopupCallback) => {
  let languages = state.languages.sort(function(a, b){  
    return state.itemsOrder.indexOf(a) - state.itemsOrder.indexOf(b);
  });
  const handleMouseEnter = () => {
    window.overPopup = true;
  };
  const handleMouseLeave = () => {
    window.overPopup = false;
  };
  if(languages.length === 0) return null;
  return (
    <div className={classNames('ly-popup-switcher-modal', state.popup_open && 'ly-is-open')} 
      onClick={togglePopupCallback}
    >
      <div className={'ly-popup-modal-content'} 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          background: state.bg_color,
          borderStyle: 'solid', 
          borderColor: state.border_color, 
          borderRadius: state.border_radius, 
          borderWidth: state.border_width,
          }}
        >
        <a href="#" 
          className="ly-close"
          onMouseEnter={handleMouseLeave}
          onMouseOut={handleMouseEnter}></a>


        {state.show_country_selector_inside_popup && state.country_switcher_enabled &&
          <>
            <h3 style={{color: `${state.text_color} !important` }}>
              Select country 
            </h3>
            {CurrencySwitcher('country', props, state, toggleCountryCallback)}          
          </>
        }


        <h3 style={{color: `${state.text_color} !important` }}>
          Select language 
        </h3>
        {state.popup_switcher_type === 'custom' ?
          CustomSwitcher(props, state, toggleCallback)
        :
          ListSwitcher(props, state)
        }


        {state.show_currency_selector_inside_popup && state.currency_switcher_enabled &&
          <>
            <h3 style={{color: `${state.text_color} !important` }}>
              Select currency 
            </h3>
            {CurrencySwitcher('currency', props, state, toggleCurrencyCallback)} 
          </>
        }
      </div>
    </div>
  );
}


export const NativeSwitcher = (props, state) => {
  let languages = state.languages.sort(function(a, b){  
    return state.itemsOrder.indexOf(a) - state.itemsOrder.indexOf(b);
  });
  if(languages.length === 0) return null;
  return (
    <select className={classNames('ly-languages-switcher', 'ly-native-select')}
      style={{
        color: state.text_color,
        backgroundColor: !state.is_transparent ? state.bg_color : 'inherit',
        padding: `${state.v_item_padding}px ${state.h_item_padding}px`,
        margin: `${state.v_item_space}px ${state.h_item_space}px`, 
        borderStyle: 'solid', 
        borderColor: state.border_color, 
        borderRadius: state.border_radius, 
        borderWidth: state.border_width,
      }}>
      {languages.map((language, index) => {
        return language.published ? <option key={`${language.iso_code}-${index}`} value={language.iso_code} style={{padding: `${state.v_item_padding}px ${state.h_item_padding}px`}}>
          {state.show_name && language.name}
          {state.show_custom_name && !language.custom_name && language.name}
          {state.show_custom_name && language.custom_name && language.custom_name}
          {state.show_iso_code && language.iso_code.toUpperCase()}
        </option> : null;
      })}
    </select>
  );
}


export const LinkSwitcher = (props, state) => {
  let languages = state.languages.sort(function(a, b){  
    return state.itemsOrder.indexOf(a) - state.itemsOrder.indexOf(b);
  });
  if(languages.length === 0) return null;
  return (
    <div className={classNames('ly-languages-switcher', 'ly-links', state.is_dark == false && 'ly-bright-theme')}>
      {languages.map((language, index) => {
        return language.published ? <a key={`${language.iso_code}-${index}`} className={'ly-languages-switcher-link'} style={{ 
          //fontSize: state.fontsize,
          backgroundColor: !state.is_transparent ? state.bg_color : 'inherit',
          color: state.text_color,
          borderStyle: 'solid', 
          borderColor: state.border_color, 
          borderRadius: state.border_radius, 
          borderWidth: state.border_width,
          margin: `${state.v_item_space}px ${state.h_item_space}px`, 
          padding: `${state.v_item_padding}px ${state.h_item_padding}px`
        }}>
          {(state.square_icons || state.round_icons || state.rectangle_icons) && <i className={classNames('ly-flag-icon', state.square_icons ? 'ly-flag-squared' : null, state.round_icons ? 'ly-flag-rounded' : null)} style={{backgroundImage: `url('/flag-icons/${language.iso_code}${state.round_icons || state.square_icons ? '_1x1' : ''}.svg')`}} />}
          
          {(state.show_name || state.show_custom_name || state.show_iso_code) &&
            <span className="ly-inner-text">
              {state.show_name && language.name}
              {state.show_custom_name && !language.custom_name && language.name}
              {state.show_custom_name && language.custom_name && language.custom_name}
              {state.show_iso_code && language.iso_code.toUpperCase()}            
            </span>
          }
        </a> : null;
      })}
    </div>
  );
}


export const ListSwitcher = (props, state) => {
  let languages = state.languages.sort(function(a, b){  
    return state.itemsOrder.indexOf(a) - state.itemsOrder.indexOf(b);
  });
  if(languages.length === 0) return null;
  return (
    <ul className={classNames('ly-languages-switcher', 'ly-list', state.is_dark == false && 'ly-bright-theme')}
      style={{
        backgroundColor: !state.is_transparent ? state.bg_color : 'inherit',
        borderStyle: 'solid', borderColor: state.border_color, borderRadius: state.border_radius, borderWidth: state.border_width,
        //padding: `${state.v_item_padding}px ${state.h_item_padding}px`,
        margin: `${state.v_item_space}px ${state.h_item_space}px`, 
      }}
    >
      {languages.map((language, index) => {
        return language.published ? <li key={`${language.iso_code}-${index}`} style={{padding: `${state.v_item_padding}px ${state.h_item_padding}px`}}>
          <a className={'ly-languages-switcher-link'} style={{color: state.text_color}}>
            {(state.square_icons || state.round_icons || state.rectangle_icons) && <i className={classNames('ly-flag-icon', state.square_icons ? 'ly-flag-squared' : null, state.round_icons ? 'ly-flag-rounded' : null)} style={{backgroundImage: `url('/flag-icons/${language.iso_code}${state.round_icons || state.square_icons ? '_1x1' : ''}.svg')`}} />}
            
            {(state.show_name || state.show_custom_name || state.show_iso_code) &&
              <span className="ly-inner-text">
                {state.show_name && language.name}
                {state.show_custom_name && !language.custom_name && language.name}
                {state.show_custom_name && language.custom_name && language.custom_name}
                {state.show_iso_code && language.iso_code.toUpperCase()}      
              </span>
            }
          </a>
        </li> : null;
      })}
    </ul>
  );
}


export const CurrencySwitcher = (scope, props, state, toggleCallback) => {
  if((!state.country_switcher_enabled && scope === 'country') || (!state.currency_switcher_enabled && scope === 'currency')) return null;
  let currencies = [];
  if(scope === 'country') {
    currencies = ['Germany (DE) - €', 'Italy (IT) - €', 'France (FR) - €'];
  }
  if(scope === 'currency') {
    currencies = (props.shop && props.shop.currencies) ? props.shop.currencies : ['EUR', 'USD', 'GBP'];
  }
  if(state.type === 'native_select') { 
    return (
      <select className={'ly-currency-switcher'}
        style={{
          color: state.text_color,
          backgroundColor: state.bg_color,
          padding: `${state.v_item_padding}px ${state.h_item_padding}px`,
          margin: `${state.v_item_space}px ${state.h_item_space}px`, 
          borderStyle: 'solid', 
          borderColor: state.border_color, 
          borderRadius: state.border_radius, 
          borderWidth: state.border_width,
        }}
      >
        {currencies.map((currency, index) => {
          return <option key={`currency-${currency}`} value={currency} style={{margin: `${state.v_item_space}px ${state.h_item_space}px`, padding: `${state.v_item_padding}px ${state.h_item_padding}px`}}>{currency}</option>;
        })}
      </select>
    );
  } else {
    let arrowScaleFactor = state.arrow_size * 0.01;
    let realsize = state.fontsize * arrowScaleFactor;
    let isDropup = state.is_dropup ? state.is_dropup : state.corner.indexOf('bottom') > -1;
    return (
      <div 
        data-dropup={isDropup} 
        onClick={toggleCallback}
        className={classNames(
          'ly-currency-switcher', 
          'ly-custom', 
          'ly-custom-dropdown-switcher', 
          state.is_dark == false && 'ly-bright-theme', 
          state[`${scope}_dropdown_open`] && 'ly-is-open',
          state.is_transparent && 'ly-is-transparent',
          isDropup && 'ly-is-dropup',
        )}
        style={{
          color: state.text_color,
          backgroundColor: !state.is_transparent ? state.bg_color : 'inherit',
          borderStyle: 'solid', 
          borderColor: state.border_color, 
          borderRadius: state.border_radius, 
          borderWidth: state.border_width,
          margin: `${state.v_item_space}px ${state.h_item_space}px`, 
        }}
      >
        <span className="ly-custom-dropdown-current" style={{color: state.text_color, padding: `${state.v_item_padding}px ${state.h_item_padding}px`}}>
          <span className="ly-custom-dropdown-current-inner ly-custom-dropdown-current-inner-text">
            {currencies[0]}
          </span>
          <span className={classNames('ly-arrow',  isDropup ? 'ly-arrow-up' : 'ly-arrow-down', state.arrow_filled ? 'caret' : 'stroke')} style={{width: realsize, height: realsize, verticalAlign: 'middle'}}>     
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${realsize} ${realsize}`} height={`${realsize}px`} width={`${realsize}px`} style={{position: 'absolute'}}>
              <path 
                d={`
                  M${ state.arrow_width } ${ state.arrow_width + realsize/4 }
                  L${ realsize/2 } ${ realsize/2 + realsize/4 }
                  L${ realsize - state.arrow_width } ${ state.arrow_width + realsize/4 }
                `}
                fill={ state.arrow_filled ? state.text_color : "transparent" }
                stroke={ state.arrow_filled ? "transparent" : state.text_color }
                strokeWidth={ state.arrow_filled ? 0 : state.arrow_width }
              />
            </svg>
          </span>  
        </span>
        <ul className={classNames(
            'ly-custom-dropdown-list', 
            state[`${scope}_dropdown_open`] && 'ly-is-open',
            state.is_dark == false && 'ly-bright-theme'
          )} 
          style={{
            background: state.bg_color, 
            borderStyle: 'solid', 
            borderColor: state.border_color, 
            borderRadius: state.border_radius, 
            borderWidth: state.border_width
          }}
        >
          {currencies.map((currency, index) => {
            return <li key={currency} style={{padding: `${state.v_item_padding}px ${state.h_item_padding}px`}}>
              <a className={classNames('ly-custom-dropdown-list-element', 'ly-languages-link', state.is_dark == false && 'ly-bright-theme')} style={{color: `${state.text_color} !important`}} href="#">
                {currency}
              </a>
            </li>;
          })}
        </ul>
      </div>
    );
  }
}


export const Recommendation = (props, state, switcher, toggleCallback) => {
  const primary_language = state.languages.find(x => x.primary == true);
  const primary_language_iso_code = !primary_language ? state.languages[0].iso_code : primary_language.iso_code;
  const strings = state.recommendation_strings[primary_language_iso_code];
  const btnStyle = {
    backgroundColor: state.recommendation_button_bg_color,
    color: state.recommendation_button_text_color,
    border: 'none',
  }
  return (
    <div className={classNames('ly-recommendation')}>
      {state.recommendation_type === 'banner' &&
        <div className={classNames('ly-banner', state.recommendation_corner)}>
          <div className={'ly-banner-content'} style={{background: state.recommendation_bg_color, fontSize: state.recommendation_fontsize, borderColor: state.recommendation_border_color, borderRadius: state.recommendation_border_radius, borderWidth: state.recommendation_border_width, borderStyle: 'solid'}}>
            <h3 style={{ margin: '0 2em 0 0', color: state.recommendation_text_color, fontSize: state.recommendation_fontsize}}>
              {(strings && strings.recommendation) ? strings.recommendation : 'Looks like your browser is set to English. Change the language?'} 
              {state.recommendation_switcher_key >= 0 && switcher}
              <button style={btnStyle}>{(strings && strings.button) ? strings.button : 'Change'}</button>
            </h3>
            <a href="#" className="ly-close" onClick={toggleCallback}></a>
          </div>
        </div>
      }
      {state.recommendation_type === 'popup' &&
        <div className={classNames('ly-popup-modal', 'ly-is-open', state.recommendation_corner)}>
          <div className={'ly-popup-modal-content'} style={{background: state.recommendation_bg_color, fontSize: state.recommendation_fontsize, borderColor: state.recommendation_border_color, borderRadius: state.recommendation_border_radius, borderWidth: state.recommendation_border_width, borderStyle: 'solid'}}>
            <h3 style={{ margin: '0 2em 1em 0', color: state.recommendation_text_color, fontSize: state.recommendation_fontsize}}>
              {(strings && strings.recommendation) ? strings.recommendation : 'Looks like your browser is set to English. Change the language?'}
            </h3>
            <a href="#" className="ly-close" onClick={toggleCallback}></a>
            <div>
              {state.recommendation_switcher_key >= 0 && switcher}
              <button style={btnStyle}>{(strings && strings.button) ? strings.button : 'Change'}</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}